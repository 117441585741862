/**
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage https://sitespring.ru
 * @licence Proprietary
 */
import BaseModel from "../../models/BaseModel";
import BaseStore from "../../models/BaseStore";

export default class SettingModel extends BaseModel {
    static entityName = 'settings';

    options() {
        return {
            ...super.options(),
            identifier: 'key'
        };
    }

    defaults() {
        return {
            key: null,
            type: null,
            value: null
        }
    }

    getRouteParameters() {
        return {
            ...super.getRouteParameters(),
            id: this.key
        }
    }

    /**
     * Get configured store of settings
     * */
    static getStore() {
        return new BaseStore([], {
            model: SettingModel,
            entityName: 'settings',
            fetchParams: {
                limit: 0
            }
        });
    }


    /**
     * Request to reset defaults
     * */
    resetToDefaults(options = {}) {
        let config = {
            url: this.getURL(this.getRoute("delete"), this.getRouteParameters()),
            method: 'DELETE',
            ...options
        };

        return this.request(config, this.onSave, this.onSaveSuccess, this.onSaveFailure);
    }
}