<script>/**
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage https://sitespring.ru
 * @licence Proprietary
 */

import BaseStore from "../../models/BaseStore";
import Informer from "../../models/Informer";
import Formatter from "../../utils/Formatter";
import compact from "lodash/compact";
import InformerForm from "./Form";

export default {
    name: "InformerList",
    components: {InformerForm},
    data() {
        return {
            store: new BaseStore([], {
                model: Informer,
                autoLoad: true,
                fetchParams: {
                    limit: 0,
                    expand: 'isVisible,authorName'
                }
            }),

            // store current editing model
            editModeForModel: null
        }
    },
    methods: {
        onActiveSwitch(informer, value) {
            informer.save({
                params: {
                    expand: 'isVisible,authorName'
                }
            });
        },
        getPeriodText(informer) {
            if (!informer.$.valid_from && !informer.$.valid_till) {
                return 'Бессрочно';
            }

            return compact([
                informer.$.valid_from && `${Formatter.asDateTime(informer.$.valid_from, "L, LTS")}`,
                informer.$.valid_till && `${Formatter.asDateTime(informer.$.valid_till, "L, LTS")}`,
            ]).join(' - ');
        },
        onEditClick(informer) {
            this.editModeForModel = informer;
        },
        async onSaveClick(informer) {
            try {
                await informer.save({
                    params: {
                        expand: 'isVisible'
                    }
                });
                this.onCancelClick();
            } catch (e) {
                this.$notify.error("Ошибка связи");
            }
        },
        onCancelClick() {
            this.editModeForModel = null;
        }
    }
}
</script>


<template>
    <div v-loading="store.loading"
         class="humanize-container">

        <el-card v-for="informer in store.models"
                 v-loading="informer.saving"
                 :key="informer.id">

            <template v-slot:header>
                <div class="toolbar">
                    <section class="grow">
                        <h4 class="name">{{informer.name}},
                            <span class="visible-text" :class="{isVisible: informer.isVisible}">cейчас {{informer.isVisible ? 'виден':'не виден'}}</span>
                        </h4>
                        <p v-if="informer.notes" class="notes">{{informer.notes}}</p>
                        <p class="period-text">
                            <el-icon class="el-icon-time"></el-icon>
                            {{getPeriodText(informer)}}
                        </p>
                    </section>
                    <el-tooltip>
                        <div slot="content">
                            Информер может быть включен или отключен в дополнение к датам показа...<br>
                            Сейчас информер {{informer.$.is_active ? 'включен' : 'выключен'}}.
                        </div>
                        <el-switch v-model="informer.is_active"
                                   :active-value="1"
                                   :inactive-value="0"
                                   @change="onActiveSwitch(informer, $event)"></el-switch>
                    </el-tooltip>
                </div>
            </template>

            <template v-slot:default>
                <informer-form v-if="editModeForModel === informer"
                               :model="informer">
                    <div class="toolbar">
                        <el-button type="primary"
                                   @click="onSaveClick(informer)">Сохранить
                        </el-button>
                        <el-button @click="onCancelClick(informer)">Отмена</el-button>
                    </div>
                </informer-form>
                <div v-else>
                    <h5 class="title" v-if="informer.title">{{informer.title}}</h5>
                    <div v-html="informer.text"></div>
                    <p class="update-notes">Ред. {{informer.updated_at|datetime}}<span v-if="informer.authorName">, {{informer.authorName}}</span></p>
                    <el-button icon="el-icon-edit"
                               @click="onEditClick(informer)">Править
                    </el-button>
                </div>
            </template>
        </el-card>

    </div>
</template>


<style scoped lang="scss">
    .toolbar, .notes, .name, .title {
        margin: 0;
    }

    .update-notes, .period-text {
        font-size: smaller;
        font-style: italic;
        color: gray;
    }

    .period-text{
        font-style: normal;
        margin-bottom: 0;
    }

    .visible-text {
        font-style: italic;
        font-family: "Lucida Console";
        color: #ed1c24;

        &.isVisible {
            color: green;
        }
    }
</style>