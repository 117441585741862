<script>
    /**
     * Wrapper to provide common submit and display formats
     *
     * @author Evgeny Shevtsov, info@sitespring.ru
     * @homepage https://sitespring.ru
     * @licence Proprietary
     */
    export default {
        name: "DatePicker",
        props: ['value'],
        data() {
            return {
                innerValue: this.value,
                dateDisplayFormat: 'dd.MM.yyyy HH:mm:ss',
                dateSubmitFormat: 'yyyy-MM-dd HH:mm:ss',
                pickerOptions: {
                    firstDayOfWeek: 1
                }
            }
        },
        watch: {
            innerValue(val) {
                this.$emit('input', val);
            }
        }
    }
</script>


<template>
    <el-date-picker :format="dateDisplayFormat"
                    :value-format="dateSubmitFormat"
                    :picker-options="pickerOptions"
                    v-model="innerValue"
                    v-bind="$attrs"></el-date-picker>
</template>


<style scoped>

</style>