<script>
    /**
     * Separate component for week days setting
     * @author Evgeny Shevtsov, info@sitespring.ru
     * @homepage https://sitespring.ru
     * @licence Proprietary
     */
    import SettingModel from "./SettingModel";
    import SettingForm from "./SettingForm";
    import DaysOfWeekWithThresholdPicker from "./DaysOfWeekWithThresholdPicker";

    export default {
        name: "DaysOfWeekWithThresholdSettingForm",
        components: {SettingForm, DaysOfWeekWithThresholdPicker},
        props: {
            model: {
                type: SettingModel,
                required: true
            }
        }
    }
</script>


<template>
    <setting-form v-bind="$attrs" :model="model">
        <days-of-week-with-threshold-picker v-model="model.value"></days-of-week-with-threshold-picker>
    </setting-form>
</template>


<style scoped lang="scss">

</style>