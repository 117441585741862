<script>
    /**
     * Base setting form view
     * @author Evgeny Shevtsov, info@sitespring.ru
     * @homepage https://sitespring.ru
     * @licence Proprietary
     */
    import SettingModel from "./SettingModel";
    import isEqual from "lodash/isEqual";

    export default {
        name: "SettingForm",
        props: {
            title: String,
            model: {
                type: SettingModel,
                required: true
            },
            inputWidth: {
                type: Number | String,
                default: 'auto'
            }
        },
        computed: {
            isLoading: vm => vm.model.loading || vm.model.saving,
            isDirty: vm => !isEqual(vm.model.$.value, vm.model.value)
        },
        methods: {
            async onSaveClick() {
                try {
                    await this.model.save();
                    this.$message.success("Настройки успешно изменены");
                } catch (e) {
                    this.$message.error("Ошибка запроса");
                }
            },
            async onResetClick() {
                try {
                    await this.model.resetToDefaults();
                    this.$message.success("Настройки успешно сброшены");
                } catch (e) {
                    this.$message.error("Ошибка запроса");
                }
            }
        }
    }
</script>


<template>
    <section class="setting" v-loading="isLoading">
        <h3 class="title" v-if="title">{{title}}</h3>
        <slot name="default" :model="model">
            <el-input v-model="model.value"
                      :style="{width:inputWidth}"></el-input>
        </slot>
        <div class="buttons" v-if="isDirty">
            <el-button type="primary" @click="onSaveClick">Сохранить</el-button>
            <el-button type="default" @click="onResetClick">Сбросить по умолчанию</el-button>
        </div>
    </section>
</template>


<style scoped lang="scss">
    .setting {
        border-bottom: 1px dotted silver;
        padding: 0 0 1rem 0;
        /*margin: 0 0 2rem 0;*/
    }

    .buttons {
        margin: .5rem 0 1rem;
    }
</style>