<script>
    /**
     * @author Evgeny Shevtsov, info@sitespring.ru
     * @homepage https://sitespring.ru
     * @licence Proprietary
     */
    import Informer from "../../models/Informer";
    import DatePicker from "../../components/DatePicker";
    import HtmlEditor from "../../components/HtmlEditor";

    export default {
        name: "InformerForm",
        components: {HtmlEditor, DatePicker},
        props: {
            model: {
                type: Informer,
                default() {
                    return new Informer();
                }
            }
        },
        data() {
            return {
                innerPeriodValue: [this.model.valid_from || '', this.model.valid_till || ''],
            }
        },
        watch: {
            innerPeriodValue(period) {
                this.model.valid_from = period && period[0] || null;
                this.model.valid_till = period && period[1] || null;
            }
        }
    }
</script>


<template>
    <el-form label-position="top">
        <el-form-item label="Период действия"
                      :error="model.errors.valid_from || model.errors.valid_till"
                      :show-message="!!model.errors.valid_from || !!model.errors.valid_till">
            <date-picker type="datetimerange"
                         start-placeholder="с"
                         end-placeholder="по"
                         v-model="innerPeriodValue"></date-picker>
        </el-form-item>

        <el-form-item label="Заголовок"
                      :error="model.errors.title"
                      :show-message="!!model.errors.title">
            <el-input v-model="model.title"
                      placeholder="Внимание!"
                      clearable
                      :maxlength="255"></el-input>
        </el-form-item>

        <el-form-item label="Текст информера"
                      :error="model.errors.text"
                      :show-message="!!model.errors.text">
            <html-editor v-model="model.text"></html-editor>
        </el-form-item>

        <el-form-item label="Текст кнопки действия"
                      :error="model.errors.action_label"
                      :show-message="!!model.errors.action_label">
            <el-input v-model="model.action_label"
                      placeholder="Понятно!"
                      clearable
                      :maxlength="128"></el-input>
        </el-form-item>

        <slot name="default"><!--Assume toolbars here...--></slot>
    </el-form>
</template>


<style scoped>

</style>