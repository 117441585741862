/**
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage https://sitespring.ru
 * @licence Proprietary
 */
import BaseModel from "./BaseModel";

export default class Informer extends BaseModel {
    static entityName = 'informer';

    defaults() {
        return {
            ...super.defaults(),
            name: '',
            title: '',
            valid_from: null,
            valid_till: null,
            is_active: false,
            updated_at: null,
            updated_by: null
        }
    }

    mutations() {
        return {
            ...super.mutations(),
            // handle NOW expression returned by server side after update
            updated_at: (str) => 'string' == typeof str ? new Date(str) : new Date()
        }
    }


    routes() {
        return {
            ...super.routes(),
            'reset-defaults': '{urlPrefix}/{entityName}/reset-defaults'
        };
    }


    /**
     * Send request to reset defaults
     * */
    requestResetDefaults() {

    }
}