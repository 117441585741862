<script>
    /**
     * @author Evgeny Shevtsov, info@sitespring.ru
     * @homepage https://sitespring.ru
     * @licence Proprietary
     */
    import find from "lodash/find";
    import filter from "lodash/filter";

    export default {
        name: "DaysOfWeekWithThresholdPicker",
        props: {
            value: {
                type: Array,
                default() {
                    return [];
                }
            }
        },
        data() {
            return {
                internalValue: this.value,
                options: [
                    {label: 'Понедельник', is_checked: false, day_of_week: 1, threshold: 0, countdown_time: null},
                    {label: 'Вторник', is_checked: false, day_of_week: 2, threshold: 0, countdown_time: null},
                    {label: 'Среда', is_checked: false, day_of_week: 3, threshold: 0, countdown_time: null},
                    {label: 'Четверг', is_checked: false, day_of_week: 4, threshold: 0, countdown_time: null},
                    {label: 'Пятница', is_checked: false, day_of_week: 5, threshold: 0, countdown_time: null},
                    {label: 'Суббота', is_checked: false, day_of_week: 6, threshold: 0, countdown_time: null},
                    {label: 'Воскресенье', is_checked: false, day_of_week: 7, threshold: 0, countdown_time: null},
                ]
            }
        },
        watch: {
            internalValue() {
                this.$emit('input', this.internalValue);
            },
            value(value) {
                this.internalValue = value;
                this.updateOptions();
            }
        },
        methods: {
            // Update internal value when any input change
            updateInternalValue() {
                this.internalValue = filter(this.options, {is_checked: true})
                    .map(option => ({
                        day_of_week: option.day_of_week,
                        threshold: option.threshold,
                        countdown_time: option.countdown_time
                    }));
            },

            // update options value according internal value
            updateOptions() {
                this.options.map(option => {
                    let day = find(this.internalValue, {day_of_week: option.day_of_week});
                    if (day) {
                        option.is_checked = true;
                        option.threshold = day.threshold || 0;
                        option.countdown_time = day.countdown_time || "00:00:00"
                    } else {
                        option.is_checked = false;
                    }
                });
            }
        },
        created() {
            // update options on startup
            this.updateOptions();
        }
    }
</script>


<template>
    <div class="list">
        <div class="row header">
            <div>День недели</div>
            <div>Порог, часов
                <el-tooltip content="Порог неактивности в часах перед днем недели">
                    <el-icon class="el-icon-question"></el-icon>
                </el-tooltip>
            </div>
            <div>Время отсчета
                <el-tooltip content="Время дня недели с которого отсчитывается порог">
                    <el-icon class="el-icon-question"></el-icon>
                </el-tooltip>
            </div>
        </div>
        <div v-for="option in options"
             :key="option.key"
             class="row list-item">
            <el-checkbox :label="option.label"
                         v-model="option.is_checked"
                         @change="updateInternalValue"></el-checkbox>
            <el-input-number v-model="option.threshold"
                             size="small"
                             v-if="option.is_checked"
                             @change="updateInternalValue"></el-input-number>
            <el-time-picker v-if="option.is_checked"
                            size="small"
                            value-format="HH:mm:ss"
                            v-model="option.countdown_time"
                            @change="updateInternalValue"></el-time-picker>
        </div>
    </div>
</template>


<style scoped lang="scss">
    .row {
        display: flex;
        align-items: center;
        height: 2.5rem;

        > * + * {
            display: inline-block;
            margin-left: 1rem;
        }

        > :first-child {
            width: 120px;
        }

        > :nth-child(2) {
            width: 150px;
        }
    }

    .header {
        font-weight: bold;
    }
</style>