<script>
import SettingForm from "@/views/settings/SettingForm";
import SettingModel from "@/views/settings/SettingModel";
import Header from "@/views/AppHeader";

/**
 * Edit sms pattern setting
 * @author Evgeny Shevtsov <info@sitespring.ru>
 * @homepage https://sitespring.ru
 * @licence Proprietary, Commercial
 */


export default {
  name: "OrderSmsForm",
  components: {Header, SettingForm},
  created() {
    console.log(this.$scopedSlots);
  }
}
</script>


<template>
  <setting-form v-bind="$attrs">
    <template v-slot:default="{model}">
      <div class="layout">
        <div class="layout__input">
          <el-input maxlength="70"
                    v-model="model.value"
                    show-word-limit></el-input>
        </div>
        <div class="layout__instructions">
          <h5 class="header">Плейсхолдеры</h5>
          <p><code>{order__}</code> - Номер заказа, 9 символов</p>
          <p><code>{phone_____}</code> - Телефон курьера, 12 символов</p>
          <p><code>{amount__}</code> - Сумма заказа, 5-10 символов</p>
        </div>
      </div>
    </template>

  </setting-form>
</template>


<style scoped lang="scss">
.layout {
  display: flex;

  &__input {
    flex: 1 0 250px;
  }
  &__instructions {
    margin-left: 3rem;
    flex: 1;
    .header {
      margin: 0;
    }
  }
}
</style>