<script>
    /**
     * Separate component for week days setting
     * @author Evgeny Shevtsov, info@sitespring.ru
     * @homepage https://sitespring.ru
     * @licence Proprietary
     */
    import SettingForm from "./SettingForm";
    import SettingModel from "./SettingModel";
    import find from "lodash/find";

    export default {
        name: "DaysOfWeekSettingForm",
        components: {SettingForm},
        props: {
            model: {
                type: SettingModel,
                required: true
            }
        },
        data() {
            return {
                internalValue: [],
                options: [
                    {value: 1, label: 'Пн'},
                    {value: 2, label: 'Вт'},
                    {value: 3, label: 'Ср'},
                    {value: 4, label: 'Чт'},
                    {value: 5, label: 'Пт'},
                    {value: 6, label: 'Сб'},
                    {value: 7, label: 'Вс'},
                ]
            }
        },
        watch: {
            internalValue: function () {
                // update model value to [1,2,..7] style
                let value = this.internalValue.map(label => find(this.options, {label}).value);
                this.model.set('value', value);
            }
        },
        methods: {
            updateInternalValue() {
                // update internal value according options [label1,...label7] style
                this.internalValue = this.model.$.value.map(value => find(this.options, {value}).label);
            }
        },
        created() {
            this.updateInternalValue();
            this.model.on('save.success', this.updateInternalValue);
        }
    }
</script>


<template>
    <setting-form v-bind="$attrs" :model="model">
        <el-checkbox-group v-model="internalValue">
            <el-checkbox v-for="option in options"
                         :key="option.key"
                         :label="option.label"></el-checkbox>
        </el-checkbox-group>
    </setting-form>
</template>


<style scoped>

</style>