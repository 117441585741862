<script>
    /**
     * @author Evgeny Shevtsov, info@sitespring.ru
     * @homepage https://sitespring.ru
     * @licence Proprietary
     */
    import SettingModel from "./SettingModel";
    import * as SettingKeys from "./SettingsKeys";
    import SettingForm from "./SettingForm";
    import DaysOfWeekSettingForm from "./DaysOfWeekSettingForm";
    import DaysOfWeekWithThresholdSettingForm from "./DaysOfWeekWithThresholdSettingForm";
    import OrderSmsForm from "@/views/settings/OrderSmsForm";

    export default {
        name: "Basics",
        components: {OrderSmsForm, DaysOfWeekWithThresholdSettingForm, DaysOfWeekSettingForm, SettingForm},
        data() {
            return {
                settings: SettingModel.getStore(),
                SettingKeys
            }
        },
        computed: {
            isLoading: vm => vm.settings.loading,
            isFetched: vm => vm.settings.length > 0,
        },
        created() {
            this.settings.fetch();
        }
    }
</script>


<template>
    <div v-loading="isLoading" class="humanize-container--long">
        <div v-if="isFetched">
            <days-of-week-with-threshold-setting-form :title="`Доступные для заказа дни доставки`"
                                                      :model="settings.find({key:SettingKeys.KEY_ORDER_ALLOWED_DELIVERY_DAYS_WITH_THRESHOLDS})">
            </days-of-week-with-threshold-setting-form>
            <setting-form title="Email оповещения о новом заказе"
                          :model="settings.find({key:SettingKeys.KEY_ORDER_EMAIL_NOTIFICATIONS})"></setting-form>
            <setting-form title="Телефон оповещения о новом заказе"
                          :model="settings.find({key:SettingKeys.KEY_ORDER_PHONE_NOTIFICATIONS})"></setting-form>
            <order-sms-form title="Шаблон СМС оповещения о передаче в доставку"
                          input-width="500px"
                          :model="settings.find({key:SettingKeys.KEY_ORDER_SMS_DELIVERY_TEMPLATE})"></order-sms-form>
        </div>
    </div>
</template>


<style scoped>

</style>